#card-imgp{
    width: 410px;
    height: 900px;
    color: #EFEFEF;
    border-radius: 8px;
}
#projetos{
    background-color: #000000;
    color: #EFEFEF;
    padding: 20px;
}
video{
    border-radius: 8px;
    width: 410px;
    height: 900px;
    object-fit: cover;
    z-index: 0;
}
.container-cardp{
    display: flex;
}
.container-projetos{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.conteudo{
    position: absolute;
    border-radius: 8px;
    z-index: 1;
    background-color: #1f1e1baf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 410px;
    height: 900px;
}
.conteudo p{
    padding: 5px;
    font-size: 12px;
}
#line{
    border-top: 1px solid #006400;
    width: 300px;
    font-size: 0px;
}
#projetos-title{
    text-align: center;
    color: #006400;
    font-family: "Inconsolata";
}
#div-item{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 5px;
    justify-content: center;
}
#linkRedes{
    font-size: 30px;
    color: #EFEFEF;
    padding: 2px;
}
#linkRedes:hover{
    transform: scale(1.3);
    transition: .8s;
}

#imgAnimase{
    object-fit: contain;
    width: 410px;
    height: 900px;
}