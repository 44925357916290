#sobremim{
    background-color: #000000;
    color: #EFEFEF;
    padding: 20px;
}
.container-sobre{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
#desc-me{
    padding: 30px;
    width: 95%;
}
.decricao{
    text-align: justify;
}