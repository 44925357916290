#skills{
    background-color: #000000;
    color: #EFEFEF;
    padding: 20px;
}

#skills-title{
    text-align: center;
    color: #006400;
    font-family: "Inconsolata";
}


#card-img{
    background-color: #1f1e1b;
    width: 180px;
    height: 250px;
    border-radius: 10px;
    align-items: center;
}
.container-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: auto;
    margin-top: 3rem;

    -webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: gray;
}
.text{
    text-align: center;
}
.container-card:hover{
    -webkit-filter: none;	
	filter: none;
    transition: .8s;
}
.container-skills{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}