#header{
    background-color: #000000;
    color: #EFEFEF;
    padding: 20px;
}
.content-header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
span{
    color:#006400;
}
#side2{
    display: flex;
    justify-content: space-between !important;
    flex-wrap: wrap;
    margin-left: 2px;
}
#side2 ul a{
    text-decoration: none;
    color: #EFEFEF;
}
#side2 ul a:hover{
    color: #006400;
    transition: 0.5s ease-in;
}