#contato{
    background-color: rgba(0,0,0,0.9);
    color: #EFEFEF;
    padding: 20px;
}
.container-footer{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}
#redes-contato{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
}
#redes-contato a{
    font-size: 30px;
    color: #EFEFEF;
    text-decoration: none;
}

#redes-contato a:hover{
    color: #373aeb;
}