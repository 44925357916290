#bemvindo{
    background-color: #000;
    color: #EFEFEF;
    padding: 20px;
}
.container-bemvindo{
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items:start;
    padding: 20px;
}
#me-title{
    font-family: "Inconsolata";
    color: #006400;
    font-size: 55px;
    position: relative;
    left: 3rem;
}
#side{
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#desc{
    font-family: "Inconsolata";
    font-size: 25px;
    white-space: nowrap;
    overflow: hidden;
    width: 30em;
    position: relative;
    left: 3rem;
    width: 25rem;
    animation: type 5s steps(60, end) infinite; 
  }
#cod{
    font-size: 10px;
    color: #006400;
    justify-content: right;
    z-index: 0;
    position: relative;
    right: 150px;
}

#img{
    width: 6rem;
    height: 6rem;
    border-radius:50%;
    object-fit:unset;
}

@keyframes type {
    from {
        width: 0;
    }
}

@keyframes type2 {
    0% {
        width: 0;
    }
    50% {
        width: 0;
    }
    100% {
        width: 100;
    }
}

@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}

@media screen and (max-width: 800px) {
    #cod{
       display: none;
    }
    #me-title{
        font-size: 40px;
    }
    #side{
        position: relative;
        right: 2rem; 
    }
  }
@media screen and (min-width: 800px) {
    #img{
        width: 10rem;
        height: 10rem;
    }
  }
